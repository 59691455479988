var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-social-view"
  }, [_c('div', {
    staticClass: "board-social-view__head"
  }, [_c('h1', {
    staticClass: "board-social-view__title"
  }, [_vm._v(_vm._s(_vm.board.subject))]), _c('span', {
    staticClass: "board-social-view__id"
  }, [_vm._v(_vm._s(_vm.board.summary))]), _c('span', {
    staticClass: "board-social-view__id"
  }, [_vm._v(_vm._s(_vm.board.period))]), _c('div', {
    staticClass: "board-social-view__head-flex"
  }, [_c('ul', {
    staticClass: "board-social-view__infos"
  }), _c('span', {
    staticClass: "board-social-view__date"
  }, [_vm._v(_vm._s(_vm.board.createDate.toDate()))])]), _c('div', {
    staticClass: "board-social-view__buttons"
  }, [_c('button', {
    staticClass: "button button--border-thumbup",
    class: {
      'button--active': _vm.isLike
    },
    on: {
      "click": function ($event) {
        !_vm.isLike ? _vm.$emit('like') : _vm.$emit('unlike');
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 330 331"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 330 331",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    attrs: {
      "d": "M298,101c-12.9-7.1-28-9.2-42.4-6.1l-31,6.7l5.1-52c2.1-20.9-10.4-40.3-30.3-47.1c-11.6-4-24.1-3.1-35,2.5\n                        c-11,5.6-19,15.1-22.6,26.8l-17.2,55.5c-4.2,13.7-14.8,24.8-28.2,29.7l-10.7,3.9H15c-8.3,0-15,6.7-15,15v160.4c0,4,1.6,7.8,4.4,10.6\n                        c2.8,2.8,6.6,4.4,10.6,4.4l60.3,0l149.5,18.9c4.5,0.6,9.1,0.9,13.6,0.9c22.9,0,45.1-7.3,63.7-21.2l3.2-2.4\n                        c15.5-11.5,24.7-29.9,24.7-49.2V154.8C330,132.4,317.7,111.7,298,101 M30,150.9h42.2l-9.9,130.4l-32.3,0L30,150.9L30,150.9z\n                        M300,258.2c0,9.9-4.7,19.3-12.6,25.2l-3.2,2.4c-16,11.9-35.7,17.1-55.5,14.6L92.3,283.2l6.5-85.9l3.8-50.6l4.1-1.5\n                        c22.1-8.1,39.5-26.4,46.5-48.9l17.2-55.5c1.2-3.9,3.9-7.1,7.6-9c3.7-1.9,7.8-2.2,11.7-0.8c6.7,2.3,10.8,8.8,10.1,15.8l-7.2,72.5\n                        c-0.5,4.8,1.4,9.5,4.9,12.7c3.6,3.2,8.5,4.5,13.2,3.5l51.1-11.1c7.4-1.6,15.1-0.5,21.7,3.1c10.1,5.5,16.3,16.1,16.3,27.5V258.2z"
    }
  })]), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("추천")])]), _c('button', {
    staticClass: "button button--border-scrap",
    class: {
      'button--active': _vm.isBookmark
    },
    on: {
      "click": function ($event) {
        !_vm.isBookmark ? _vm.$emit('bookmark') : _vm.$emit('unbookmark');
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "stroke-width": "2.2px",
      "stroke-linecap": "butt",
      "stroke-linejoin": "round",
      "fill": "none",
      "d": "M22.100,14.100 L4.100,28.100 L4.100,4.100 L22.100,4.100 L22.100,28.100 L13.100,22.100 "
    }
  })]), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("스크랩")])]), _c('button', {
    staticClass: "button button--border-share",
    on: {
      "click": function ($event) {
        return _vm.$emit('share');
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 354 354"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 354 354",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    attrs: {
      "d": "M289,129c25.9,0,49.2-15.6,59.1-39.5s4.4-51.4-13.9-69.7C315.9,1.4,288.4-4,264.5,5.9S225,39.1,225,65c0.1,6,1,12,2.8,17.8\n                        L114,136.3c-17.2-20.8-45.6-28.7-71-19.5C17.5,125.9,0.6,150,0.6,177s17,51.1,42.4,60.2c25.4,9.1,53.8,1.3,71-19.5l113.9,53.5\n                        C226,277,225.1,283,225,289c-0.1,30.6,21.4,57,51.4,63c30,6.1,60.1-9.9,71.9-38.1c11.8-28.2,2.1-60.8-23.2-78\n                        c-25.3-17.1-59.2-14-81,7.5l-116.3-54.7c1.6-7.8,1.6-15.9,0-23.7l116.3-54.7C256,122.4,272.2,129,289,129 M289,33\n                        c12.9,0,24.6,7.8,29.6,19.8c5,12,2.2,25.7-6.9,34.9c-9.2,9.2-22.9,11.9-34.9,6.9c-12-5-19.8-16.6-19.8-29.6\n                        C257,47.3,271.3,33,289,33 M65,209c-12.9,0-24.6-7.8-29.6-19.8c-5-12-2.2-25.7,6.9-34.9c9.2-9.2,22.9-11.9,34.9-6.9\n                        c12,5,19.8,16.6,19.8,29.6C97,194.7,82.7,209,65,209 M289,257c12.9,0,24.6,7.8,29.6,19.8c5,12,2.2,25.7-6.9,34.9\n                        c-9.2,9.2-22.9,11.9-34.9,6.9c-12-5-19.8-16.6-19.8-29.6C257,271.3,271.3,257,289,257"
    }
  })]), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("공유")])])])]), _c('div', {
    staticClass: "board-social-view__body"
  }, [_c('div', {
    staticClass: "product-details__contents"
  }, [_c('div', {
    staticClass: "product-details__content",
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })]), _c('div', {
    staticClass: "board-view-footnote"
  }, [_c('ul', {
    staticClass: "board-view-footnote__lists"
  }, [_c('li', {
    staticClass: "board-view-footnote__list"
  }, [_vm._v("조회 " + _vm._s(_vm.board.viewCount))]), _c('li', {
    staticClass: "board-view-footnote__list"
  }, [_vm._v("좋아요 " + _vm._s(_vm.board.likeCount))]), _c('li', {
    staticClass: "board-view-footnote__list"
  }, [_vm._v("공유 " + _vm._s(_vm.board.shareCount))])])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }