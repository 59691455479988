<template>
    <div v-if="board" class="board-container">
        <div class="board-social-view">
            <div class="board-social-view__head">
                <h1 class="board-social-view__title">{{ board.subject }}</h1>
                <span class="board-social-view__id">{{ board.summary }}</span>
                <span class="board-social-view__id">{{ board.period }}</span>
                <div class="board-social-view__head-flex">
                    <ul class="board-social-view__infos">
                        <!-- <li class="board-social-view__info">빌라</li>
                        <li class="board-social-view__info">종합</li>
                        <li class="board-social-view__info">40평</li>
                        <li class="board-social-view__info">2300만</li> -->
                    </ul>
                    <span class="board-social-view__date">{{ board.createDate.toDate() }}</span>
                </div>
                <div class="board-social-view__buttons">
                    <button class="button button--border-thumbup" :class="{ 'button--active': isLike }" @click="!isLike ? $emit('like') : $emit('unlike')">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 331" style="enable-background:new 0 0 330 331;" xml:space="preserve">
                            <path d="M298,101c-12.9-7.1-28-9.2-42.4-6.1l-31,6.7l5.1-52c2.1-20.9-10.4-40.3-30.3-47.1c-11.6-4-24.1-3.1-35,2.5
                            c-11,5.6-19,15.1-22.6,26.8l-17.2,55.5c-4.2,13.7-14.8,24.8-28.2,29.7l-10.7,3.9H15c-8.3,0-15,6.7-15,15v160.4c0,4,1.6,7.8,4.4,10.6
                            c2.8,2.8,6.6,4.4,10.6,4.4l60.3,0l149.5,18.9c4.5,0.6,9.1,0.9,13.6,0.9c22.9,0,45.1-7.3,63.7-21.2l3.2-2.4
                            c15.5-11.5,24.7-29.9,24.7-49.2V154.8C330,132.4,317.7,111.7,298,101 M30,150.9h42.2l-9.9,130.4l-32.3,0L30,150.9L30,150.9z
                            M300,258.2c0,9.9-4.7,19.3-12.6,25.2l-3.2,2.4c-16,11.9-35.7,17.1-55.5,14.6L92.3,283.2l6.5-85.9l3.8-50.6l4.1-1.5
                            c22.1-8.1,39.5-26.4,46.5-48.9l17.2-55.5c1.2-3.9,3.9-7.1,7.6-9c3.7-1.9,7.8-2.2,11.7-0.8c6.7,2.3,10.8,8.8,10.1,15.8l-7.2,72.5
                            c-0.5,4.8,1.4,9.5,4.9,12.7c3.6,3.2,8.5,4.5,13.2,3.5l51.1-11.1c7.4-1.6,15.1-0.5,21.7,3.1c10.1,5.5,16.3,16.1,16.3,27.5V258.2z"/>
                        </svg>
                        <span class="d-sr-only">추천</span>
                    </button>
                    <button class="button button--border-scrap" :class="{ 'button--active': isBookmark }" @click="!isBookmark ? $emit('bookmark') : $emit('unbookmark')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path fill-rule="evenodd" stroke-width="2.2px" stroke-linecap="butt" stroke-linejoin="round" fill="none" d="M22.100,14.100 L4.100,28.100 L4.100,4.100 L22.100,4.100 L22.100,28.100 L13.100,22.100 "/>
                        </svg>
                        <span class="d-sr-only">스크랩</span>
                    </button>
                    <button class="button button--border-share" @click="$emit('share')">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 354 354" style="enable-background:new 0 0 354 354;" xml:space="preserve">
                            <path d="M289,129c25.9,0,49.2-15.6,59.1-39.5s4.4-51.4-13.9-69.7C315.9,1.4,288.4-4,264.5,5.9S225,39.1,225,65c0.1,6,1,12,2.8,17.8
                            L114,136.3c-17.2-20.8-45.6-28.7-71-19.5C17.5,125.9,0.6,150,0.6,177s17,51.1,42.4,60.2c25.4,9.1,53.8,1.3,71-19.5l113.9,53.5
                            C226,277,225.1,283,225,289c-0.1,30.6,21.4,57,51.4,63c30,6.1,60.1-9.9,71.9-38.1c11.8-28.2,2.1-60.8-23.2-78
                            c-25.3-17.1-59.2-14-81,7.5l-116.3-54.7c1.6-7.8,1.6-15.9,0-23.7l116.3-54.7C256,122.4,272.2,129,289,129 M289,33
                            c12.9,0,24.6,7.8,29.6,19.8c5,12,2.2,25.7-6.9,34.9c-9.2,9.2-22.9,11.9-34.9,6.9c-12-5-19.8-16.6-19.8-29.6
                            C257,47.3,271.3,33,289,33 M65,209c-12.9,0-24.6-7.8-29.6-19.8c-5-12-2.2-25.7,6.9-34.9c9.2-9.2,22.9-11.9,34.9-6.9
                            c12,5,19.8,16.6,19.8,29.6C97,194.7,82.7,209,65,209 M289,257c12.9,0,24.6,7.8,29.6,19.8c5,12,2.2,25.7-6.9,34.9
                            c-9.2,9.2-22.9,11.9-34.9,6.9c-12-5-19.8-16.6-19.8-29.6C257,271.3,271.3,257,289,257"/>
                        </svg>
                        <span class="d-sr-only">공유</span>
                    </button>
                </div>
            </div>
            <div class="board-social-view__body">
                <div class="product-details__contents">
                    <div class="product-details__content" v-html="board.content">

                        <!-- <p class="product-details__description">설명문구..</p>
                        <img class="product-details__content-image" src="">
                        <ul class="product-list__tags">
                            <li class="product-list__tag">#tag</li>
                            <li class="product-list__tag">#tag2</li>
                        </ul> -->
                    </div>
                </div>
                <div class="board-view-footnote">
                    <ul class="board-view-footnote__lists">
                        <li class="board-view-footnote__list">조회 {{ board.viewCount }}</li>
                        <li class="board-view-footnote__list">좋아요 {{ board.likeCount }}</li>
                        <li class="board-view-footnote__list">공유 {{ board.shareCount }}</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null }
    },
    computed: {
        isBookmark(){
            return this.me ? this.me.bookmarks.find(bookmark => bookmark._board == this.board._id) : false;
        },
        isLike(){
            return this.me ? this.me.likes.find(like => like._board == this.board._id) : false;
        }
    }
}
</script>
